<template>
    <section class="tables">
        <div class="page-header">
            <h3 class="page-title">
                {{ 'Hi ' + getCurrentUser.userName + ', Welcome To ' + getRoom.name }}
            </h3>

            <h3 class="page-title">Room ID: {{ roomId }}</h3>

            <!-- <button type="button" class="btn btn-outline-success btn-fw">{{ roomId }}</button> -->

            <b-button @click="startGame" variant="success" class="">Start Game</b-button>
            <b-button @click="quiteGame" variant="danger" class="mr-2">End Session</b-button>

        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <b-modal ref="job-modal" hide-footer :no-close-on-backdrop="true" id="modallg" title="Job Detail"
                            size="lg" :footer-bg-variant="'info'">
                            <div class="col-md-12">

                            </div>
                            <!-- <b-button class="mt-3 subbtn float-end" variant="outline-success" @click="() => {this.selectedJobId = null, this.$refs['job-modal'].hide();}">close</b-button> -->
                        </b-modal>
                        <h4 class="card-title">Connected Players</h4>
                        <div class="float-right">
                            <!-- search field -->
                            <!-- <b-input v-model="filter" placeholder="Search Job" id="job-search" style="padding: 10px"></b-input> -->
                        </div>
                        <b-table :items="items" id="table-list" responsive :per-page="perPage" :current-page="currentPage"
                            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" show-empty :filter="filter"
                            striped hover>

                            <!-- when no item found -->
                            <template #empty="">
                                <p class="text-muted text-center">{{ noItems }}</p>
                            </template>

                        </b-table>
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="table-list" align="right">
                        </b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

export default {
    data() {
        return {
            arg: {},
            welcomeMessage: null,
            players: [],
            sortBy: 'name',
            perPage: 10,
            currentPage: 1,
            sortDesc: false,
            sortByFormatted: true,
            filterByFormatted: true,
            filter: "",
            sortable: true,
            fields: [
                { key: 'playerName', sortable: true },
                { key: 'job', sortable: true },
                { key: 'netMonthlySalary', sortable: true },
            ],
            items: [],
            noItems: null,
            roomId: null
        }
    },
    computed: {
        ...mapGetters(["getCurrentUser", "getRoom", 'getRoomInformation', 'getGlobalConnectionInfo']),
        rows() {
            return this.items.length
        }
    },
    methods: {
        ...mapActions([
            "createRoom",
            'setRoomInformation',
            'checkIfRoomIsActive',
            'setGlobalConnectionInfo'
        ]),
        async setPlayers() {
            this.items = [];
            this.items = await this.getRoomInformation.map((info) => {
                return {
                    playerName: info.player.firstName + " " + info.player.lastName,
                    job: info.job.name,
                    annualSalary: info.job.annualSalary,
                    grossMonthlySalary: info.job.grossMonthlySalary,
                    netMonthlySalary: info.job.netMonthlySalary,
                };
            });
        },
        async startGame() {
            this.$socket.emit('startGame', { roomId: this.roomId });
        },
        async quiteGame() {
            this.$socket.emit('endSession', { roomId: this.roomId });

        }
    },

    async created() {

        this.roomId = this.$route.params.id;

        let response = await this.checkIfRoomIsActive(this.roomId)
        if (response.status == 400) {
            this.$router.push({ name: 'dashboard' });
        }
        localStorage.setItem("roomId", this.roomId)
        this.$socket.emit("joinRoom", {
            playerId: this.getCurrentUser.id,
            roomId: this.roomId,
            isAdmin: true,
        });

        this.$socket.on("welcomeAdminRoom", async (arg) => {

            await this.setGlobalConnectionInfo(arg);

            console.log("welcomeAdminRoom", arg);

        });

        this.$socket.on("playerJoined", async (data) => {

            console.log('playerJoined', data);
            await this.setRoomInformation(this.roomId);
            await this.setPlayers();

        });

        this.$socket.on('gameStart', (data) => {
            console.log('gameStart', data);
        })

        this.$socket.on('gameOver', async(data) => {
            console.log('gameover', data);
            
            this.$socket.emit('leaveRoom', { roomName: this.getGlobalConnectionInfo.roomName });
            localStorage.removeItem('roomId');
            await this.setGlobalConnectionInfo({});

            this.$toast.add({ severity: 'success', summary: `Session`, detail: 'Session Disconnected Successfully.', life: 3000 });        
            this.$router.push({ name: 'room-score', params: { roomId: this.roomId } });
        });
    },
    async mounted() {

        await this.setRoomInformation(this.roomId);
        await this.setPlayers();
    },
}
</script>